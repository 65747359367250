import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import Navigation from "./navigation";

import style from "../styles/post.module.css";

const Post = ({
  title,
  date,
  path,
  coverImage,
  author,
  liveUrl,
  sampleCode,
  sourceCode,
  tags,
  html,
  previousPost,
  nextPost,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path;
  const previousLabel = previousPost && previousPost.frontmatter.title;
  const nextPath = nextPost && nextPost.frontmatter.path;
  const nextLabel = nextPost && nextPost.frontmatter.title;

  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <h1 className={style.title}>{title}</h1>

        {coverImage && (
          <Img
            fluid={coverImage.childImageSharp.fluid}
            className={style.coverImage}
          />
        )}

        <div className={style.buttonContainer}>
          {sampleCode && (
            <div
              className={style.readMore}
              onClick={() => navigate("#sample_code")}
            >
              Sample Code
            </div>
          )}
          {sourceCode && (
            <a
              href={sourceCode}
              target="_blank"
              rel="noreferrer"
              className={style.readMore}
            >
              Source Code
            </a>
          )}
          {liveUrl && (
            <a
              href={liveUrl}
              target="_blank"
              rel="noreferrer"
              className={style.readMore}
            >
              Live Website
            </a>
          )}
        </div>

        <>
          <div dangerouslySetInnerHTML={{ __html: html }} id="sample_code" />
          <Navigation
            previousPath={previousPath}
            previousLabel={previousLabel}
            nextPath={nextPath}
            nextLabel={nextLabel}
          />
        </>
      </div>
    </div>
  );
};

Post.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  liveUrl: PropTypes.string,
  sourceCode: PropTypes.string,
  sampleCode: PropTypes.bool,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
};

export default Post;
